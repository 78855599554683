import {Button, CircularProgress, Input} from "@nextui-org/react";
import classNames from "classnames";
import {useState} from "react";
import VerificationInput from "react-verification-input";
import APIClient from "../api";
import {useToken} from "../hooks/useToken";
import {useNavigate} from "react-router-dom";
import {useSearchParam} from "react-use";
import {useUser} from "../hooks/useUser";
import {wait} from "../utils/waiter";
import {FaAngleRight} from "react-icons/fa";
import styled from "styled-components";

const StyledVerificationInput = styled.div`
  .character {
    border: none;
    font-size: 20px;
    border-radius: 8px;

    color: #272729;
    background-color: #f6f5fa;
    box-shadow: 0 2px 0 #e4e2f5;
  }
`;

export default function Login() {
  const navigate = useNavigate();
  const returnUrl = useSearchParam("returnUrl");

  const [, setToken] = useToken();
  const [, setUser] = useUser();

  const [step, setStep] = useState("phone");

  const [phone, setPhone] = useState("");
  const [invalidPhone, setInvalidPhone] = useState(false);

  const [sendLoading, setSendLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  async function handleSendCode() {
    //手机号码校验
    const invalid = !phone.match(/^1[3-9]\d{9}$/);
    setInvalidPhone(invalid)
    if (invalid) return;

    const waiter = wait(2000);
    try {
      setSendLoading(true);
      setUser(undefined);
      await APIClient.user.sendCode(phone);
      await waiter;
      setStep("code");
    } finally {
      await waiter;
      setSendLoading(false);
    }
  }

  async function handleLogin(code: string) {
    const waiter = wait(2000);
    try {
      setLoginLoading(true);
      const token = await APIClient.user.loginByCode(phone, code);
      await waiter;
      setToken(token);
      if (returnUrl) navigate(decodeURIComponent(returnUrl), {replace: true});
      else navigate("/", {replace: true});
    } finally {
      await waiter;
      setLoginLoading(false);
    }
  }

  function renderBody() {
    return (
      <div className="w-[340px] h-full pt-[20vh] flex flex-col justify-start gap-10">
        <div className="flex justify-center items-center">
          <img src="/octopus.png" alt="octopus" className="h-20 w-20"/>
        </div>
        <div>
          <div className="pl-2">
            <div className="text-lg font-black">{step === "phone" ? "请输入手机号码注册并登录" : "请查收您的短信验证码"}
            </div>
            {step === 'code' ?
              <div className="text-sm">手机号码: <span className="text-primary">{phone}</span></div> :
              <div/>
            }
          </div>
          <div className="pt-2">
            {
              step === 'phone' ?
                <Input
                  type="tel"
                  autoFocus
                  size="lg"
                  color="primary"
                  variant="bordered"
                  radius="sm"
                  placeholder="+86"
                  disabled={sendLoading}
                  isInvalid={invalidPhone}
                  errorMessage={invalidPhone && "请填写正确的手机号码"}
                  value={phone}
                  onValueChange={(phone) => {
                    setInvalidPhone(false);
                    setPhone(phone);
                  }}
                /> :
                loginLoading ?
                  <div className="pt-6 flex justify-center"><CircularProgress aria-label="loginLoading"/></div> :
                  <div className="flex flex-col justify-center items-center gap-4">
                    <StyledVerificationInput>
                      <VerificationInput classNames={{
                        character: "character"
                      }} autoFocus validChars="0-9" onComplete={handleLogin}/>
                    </StyledVerificationInput>
                    <div className="text-primary text-sm cursor-pointer"
                         onClick={() => setStep("phone")}>没收到验证码?点击重新发送
                    </div>
                  </div>
            }
          </div>
        </div>
        <div className="flex justify-center">
          {
            step === 'phone' ?
              <Button
                className="rounded-full" color="primary" isIconOnly size="md" isLoading={sendLoading}
                onClick={handleSendCode}
              ><FaAngleRight size={20}/></Button> :
              null
          }
        </div>
      </div>
    )
  }

  return (
    <div className="h-full w-full flex justify-center items-center bg-gray-100">
      <div className={classNames(
        "h-full",
        "flex flex-col justify-center items-center",
        "w-full px-4",
        "md:w-[720px]",
      )}>
        {renderBody()}
      </div>
    </div>
  )
}

import {QuestionRun, Step} from "./session";
import {SiAnswer} from "react-icons/si";
import Markdown from "../../components/Markdown";
import {BiStar} from "react-icons/bi";
import {FaInfoCircle, FaInternetExplorer, FaQuestion} from "react-icons/fa";
import classNames from "classnames";
import {BounceLoader, HashLoader} from "react-spinners";
import {CgCheckO} from "react-icons/cg";
import {FaCircleQuestion} from "react-icons/fa6";
import {useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

enum StepName {
  CoreQuestion = "core-question",
  GoogleSearch = "google-search",
  WebFetch = "web-fetch",
  MemorySearch = "memory-search",
}

export default function RunBlock({run, ask, isLatest}: {
  run: QuestionRun,
  ask: (question: string) => Promise<void>,
  isLatest: boolean
}) {
  const related = run.state.steps?.find(it => it.name === "google-search")?.data as Array<string> ?? [];
  return (
    <div className="py-5 space-y-5">
      {/*用户问题*/}
      <div className="text-3xl font-light animate__animated animate__fadeIn">
        {run.question}
      </div>

      {/*步骤块*/}
      <StepsBlock steps={run.state.steps} defaultExpand={isLatest}/>

      {/*正在总结信息*/}
      {run.state.messages && run.state.messages.length === 0 && (
        <div className="flex items-center space-x-3 animate__animated animate__fadeIn">
          <div className="px-1"><HashLoader size={18}/></div>
          <div className="text-small font-bold">正在总结</div>
        </div>
      )}

      {/*回答*/}
      {run.state.messages && run.state.messages.length > 0 && (
        <div>
          <div className="flex items-center animate__animated animate__fadeIn">
            <SiAnswer size={24} className="mr-3"/>
            <div className="font-bold mr-2">答案</div>
          </div>
          <div className="px-6 py-2">
            <Markdown content={run.state.messages.join('')}/>
          </div>
        </div>
      )}

      {/*相关问题*/}
      {isLatest && run.completed && related.length > 0 && (
        <div>
          <div className="flex items-center animate__animated animate__fadeIn">
            <BiStar size={24} className="mr-3"/>
            <div className="font-bold mr-2">相关问题</div>
          </div>
          <div className="p-4 space-y-1">
            {related.slice(0, 3).map((r, index) => (
              <div key={index} className="flex items-center animate__animated animate__fadeIn"
                   style={{animationDelay: `${index * 100}ms`}}>
                <FaQuestion className="mr-3"/>
                <div className="cursor-pointer mr-2 hover:font-bold" onClick={() => ask(r)}>
                  {r}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
      }

      {/*错误提示*/}
      {run.completed && run.error && (
        <div className={classNames(
          "mx-4 px-4 py-2",
          "border-2 border-danger-300 ",
          "rounded-lg hover:shadow-lg",
          "text-small font-light",
        )}>发生错误啦! 请稍后重试 -_-!</div>
      )}
    </div>
  )
}

function StepsBlock({steps, defaultExpand}: { steps?: Array<Step>, defaultExpand: boolean }) {
  if (typeof steps === "undefined")
    return null;
  if (steps.length === 0)
    return null;
  if (steps.length === 1 && steps[0].name === StepName.CoreQuestion && steps[0].completed)
    return null;

  return (
    <div className={classNames(
      "rounded-xl",
      "p-4 bg-white",
      "space-y-4"
    )}>
      {steps.map((step, index) => {
        switch (step.name as StepName) {
          case StepName.CoreQuestion:
            return <CoreQuestionStep key={index} name={step.name} data={step.data} completed={step.completed}/>
          case StepName.GoogleSearch:
            return <GoogleSearchStep key={index} name={step.name} data={step.data} completed={step.completed}/>
          case StepName.WebFetch:
            return <WebFetchStep key={index} name={step.name} data={step.data} completed={step.completed}/>
          case StepName.MemorySearch:
            return <MemorySearchStep key={index} name={step.name} data={step.data} completed={step.completed}
                                     defaultExpand={defaultExpand}/>
          default:
            return null;
        }
      })}
    </div>
  )
}

//核心问题步骤
function CoreQuestionStep({data, completed}: Step) {
  if (!completed) {
    return (
      <div className="flex items-center space-x-1 animate__animated animate__fadeIn">
        <div><BounceLoader size={18}/></div>
        <div className="text-sm">正在分析核心问题</div>
      </div>
    );
  }
  if (data)
    return (
      <div className="flex items-center space-x-1 animate__animated animate__fadeIn">
        <FaCircleQuestion size={17}/>
        <div className="text-sm min-w-[60px]">核心问题:</div>
        <div className="text-sm font-bold truncate">{data}</div>
      </div>
    );
  return null;
}

function GoogleSearchStep({completed}: Step) {
  if (!completed)
    return (
      <div className="flex items-center space-x-1 animate__animated animate__fadeIn">
        <div><BounceLoader size={18}/></div>
        <div className="text-sm">正在全网搜索信息</div>
      </div>
    );
  return null;
}

function WebFetchStep({data, completed}: Step) {
  const [isExpanded, setIsExpanded] = useState(false);
  const fetches = data as Array<{ title: string, url: string, completed: boolean }>;
  const fetching = fetches.filter(it => !it.completed);

  if (fetching.length > 0) {
    return (
      <div className="flex items-center space-x-1 animate__animated animate__fadeIn">
        <div><BounceLoader size={18}/></div>
        <div className="text-sm">正在分析网页信息 ({fetches.length - fetching.length} / {fetches.length})</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center space-x-1 animate__animated animate__fadeIn cursor-pointer"
           onClick={() => setIsExpanded(v => !v)}
      >
        <div><FaInternetExplorer size={17}/></div>
        <div className="text-sm">分析了 {fetches.length} 个网站</div>
        <div>
          {isExpanded
            ? <IoIosArrowUp/>
            : <IoIosArrowDown/>
          }
        </div>
      </div>
      {isExpanded && (
        <div className={classNames(
          "p-4",
          "grid grid-cols-2 gap-2",
          "sm:grid-cols-3 sm:gap-3",
          "md:grid-cols-4 md:gap-4",
        )}>
          {fetches.map((fetch, index) => (
            <div key={index}
                 className={classNames(
                   "h-24 p-2",
                   "border rounded shadow",
                   "flex flex-col justify-between",
                   "cursor-pointer hover:shadow-lg",
                   "animate__animated animate__fadeIn"
                 )}
                 style={{animationDelay: `${index * 25}ms`}}
                 onClick={() => window.open(fetch.url)}>
              <div className="text-sm font-bold">{fetch.title.substring(0, 20)}</div>
              <div className="flex justify-between items-center">
                <div className="text-tiny truncate">{new URL(fetch.url).hostname}</div>
                <div className="px-1">
                  {
                    fetch.completed
                      ? <CgCheckO color="green" size={18}/>
                      : <HashLoader size={18}/>
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function MemorySearchStep({data, completed, defaultExpand}: (Step & { defaultExpand: boolean })) {
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const memories = data as Array<{ title: string, url: string }>;

  if (!completed)
    return (
      <div className="flex items-center space-x-1 animate__animated animate__fadeIn">
        <div><BounceLoader size={18}/></div>
        <div className="text-sm">正在分析参考信息</div>
      </div>
    );
  if (memories.length > 0)
    return (
      <div>
        <div className="flex items-center space-x-1 animate__animated animate__fadeIn cursor-pointer"
             onClick={() => setIsExpanded(v => !(v ?? false))}
        >
          <div><FaInfoCircle size={17}/></div>
          <div className="text-sm">参考了 {memories.length} 条信息</div>
          <div>
            {isExpanded ?? defaultExpand
              ? <IoIosArrowUp/>
              : <IoIosArrowDown/>
            }
          </div>
        </div>
        {(isExpanded ?? defaultExpand) && (
          <div className={classNames(
            "p-4",
            "grid grid-cols-2 gap-2",
            "sm:grid-cols-3 sm:gap-3",
            "md:grid-cols-4 md:gap-4",
          )}>
            {memories.map((hit, index) => (
              <div key={index}
                   className="h-24 border rounded shadow p-2 flex flex-col justify-between cursor-pointer hover:shadow-lg animate__animated animate__fadeIn"
                   style={{animationDelay: `${index * 100}ms`}}
                   onClick={() => {
                     let url = hit.url;
                     if (url.startsWith("object://")) {
                       url = url.replace("object://", '/api/object/');
                     }
                     window.open(url);
                   }}>
                <div className="text-sm font-bold">{hit.title.substring(0, 20)}</div>
                <div className="flex justify-between items-center">
                  <div className="text-tiny truncate">{new URL(hit.url).hostname}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  return null;
}

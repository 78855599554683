import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Input,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Upload from "rc-upload";
import {toast} from "react-hot-toast";
import classNames from "classnames";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {FaSearch} from "react-icons/fa";
import {getToken} from "../../../hooks/useToken";
import APIClient, {DocumentKnowledgeData, Knowledge} from "../../../api";
import {useDebounce} from "ahooks";
import {FaCircleCheck} from "react-icons/fa6";
import {CgSearchLoading} from "react-icons/cg";
import dayjs from "dayjs";
import {wait} from "../../../utils/waiter";

export default function KnowledgeList() {
  const params = useParams();
  const knowledgeBaseId = params.knowledgeBaseId!;
  const [knowledgeList, setKnowledgeList] = useState<Knowledge[]>([]);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, {wait: 1500})

  useEffect(() => {
    (async () => {
      const knowledgeList = await APIClient.knowledge.list(knowledgeBaseId, debouncedKeyword);
      setKnowledgeList(knowledgeList.map(it => {
        it.data.error = it.error;
        return {...it, isDeleting: false}
      }));
    })()
  }, [knowledgeBaseId, timestamp, debouncedKeyword, setKnowledgeList]);

  //定时刷新索引中的数据
  useEffect(() => {
    const interval = setInterval(() => {
      const needFresh = knowledgeList.find(it => !it.data.vectored)
      if (needFresh) {
        setTimestamp(new Date().getTime());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [knowledgeList]);


  const columns = [
    {key: "title", name: "标题",},
    {key: "createAt", name: "创建时间",},
    {key: "updateAt", name: "更新时间",},
    {key: "memories", name: "索引状态",},
    {key: "actions", name: "操作",}
  ]

  async function handleDelete(knowledgeDocument: DocumentKnowledgeData) {
    const waiter = wait();
    try {
      setKnowledgeList(knowledgeList.map(it => ({...it, isDeleting: it.data.id === knowledgeDocument.id})));
      await APIClient.knowledge.del(knowledgeDocument.knowledgeBaseId, knowledgeDocument.knowledgeId);
      await waiter;
    } finally {
      setKnowledgeList(knowledgeList.map(it => ({...it, isDeleting: false})));
      await waiter;
      setTimestamp(new Date().getTime());
    }
  }

  const renderCell = (knowledge: Knowledge, columnKey: React.Key) => {
    let knowledgeDocument = knowledge.data;
    switch (columnKey) {
      case "title":
        return (
          <TableCell><p className="truncate">{knowledgeDocument.title}</p>
          </TableCell>)
      case "createAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(knowledgeDocument.createAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "updateAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(knowledgeDocument.updateAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "memories":
        const flag = knowledgeDocument.vectored;
        return (
          <TableCell className="text-center w-[120px]">
            <Chip size="sm" variant="flat" color={flag ? 'success' : 'warning'}
                  startContent={flag ? <FaCircleCheck size={18}/> : <CgSearchLoading size={18}/>}>
              {flag ? '索引完成' : '索引中'}
            </Chip>
          </TableCell>
        );
      case "actions":
        return (
          <TableCell className="w-[180px]">
            <div className="flex justify-center space-x-2">
              <div>
                <Button isLoading={knowledge["isDeleting"]}
                        variant="ghost" size="sm" color="danger"
                        onClick={() => handleDelete(knowledgeDocument)}>删除</Button>
              </div>
            </div>
          </TableCell>
        )
      default:
        return (<TableCell>...</TableCell>)
    }
  };

  return (<>
    <Card>
      <CardHeader className="px-6 flex justify-between h-16">
        <div className="flex space-x-2">
          <Input variant="bordered" size="sm"
                 labelPlacement={"outside-left"}
                 placeholder="搜索"
                 isClearable
                 startContent={<FaSearch/>}
                 className="max-w-xs"
                 value={keyword}
                 onValueChange={setKeyword}
                 onClear={() => setKeyword('')}
          />
        </div>
        <div className="flex items-center space-x-2">
          <Uploader knowledgeBaseId={knowledgeBaseId} onFresh={() => setTimestamp(new Date().getTime())}/>
        </div>
      </CardHeader>
      <CardBody>
        <Table shadow="none" aria-label="知识列表">
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                key={column.key}
                className={classNames(
                  column.key === "title" ? "" : "text-center"
                )}
              >{column.name}</TableColumn>
            )}
          </TableHeader>
          <TableBody items={knowledgeList}>
            {(item) => (
              <TableRow key={item.data.id}>
                {(columnKey) => renderCell(item, columnKey)}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  </>)
}

function Uploader({knowledgeBaseId, onFresh}: { knowledgeBaseId: string, onFresh: () => void }) {
  const [isLoading, setIsLoading] = useState(false);

  function handleSuccess(body: Record<string, any>) {
    setIsLoading(false);
    const isSuccess = body.success;
    if (!isSuccess) {
      toast.error(body.message || "");
    }
    onFresh();
  }

  function handleError(event: Error, body?: Object) {
    setIsLoading(false);
    toast.error(event.message)
    onFresh();
  }

  return (
    <>
      <Upload
        accept=".docx,.pdf,.jpg,.png,.jpg,.jpeg,.gif,.markdown,.md" multiple
        action={`/api/knowledge-base/${knowledgeBaseId}/knowledge/upload`}
        headers={{"TOKEN": getToken() ?? ""}}
        onSuccess={handleSuccess}
        onError={handleError}
        onProgress={() => setIsLoading(true)}
      >
        <div className={classNames(
          "w-[98px] h-[32px]",
          "border-2 rounded-lg border-secondary",
          "flex justify-center items-center space-x-1",
          "text-secondary",
          "hover:bg-secondary hover:text-white",
          "transition-background transition-colors duration-200 ease-in",
        )}>
          <div className="flex justify-center items-center w-5">
            {isLoading
              ? <Spinner color="secondary" className="scale-80" size="sm"/>
              : <AiOutlineCloudUpload size={18}/>
            }
          </div>
          <div className="text-[12px]">{isLoading ? "正在处理" : "上传文档"}</div>
        </div>
      </Upload>
    </>
  )
}

import axios, {AxiosError, type AxiosInstance, type AxiosRequestConfig} from "axios";
import {jsonParse} from "../utils/json";
import {APIAuthenticationError, APICommonError, APIError} from "./error";
import {CompletedData, WebSocketEx} from "../utils/websocket";
import {EventStream} from "../utils/event_stream";
import {Knowledge, KnowledgeBase, KnowledgeBaseUpsert, ToolEnabled, User} from "./model";
import {getToken} from "../hooks/useToken";
import {RunState} from "../utils/run_state";

export class Client {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: "/api",
      transformResponse: (data: any, _, status) => {
        if (status && status <= 400) {
          return jsonParse(data)
        }
        return null
      }
    });
    this.axiosInstance.interceptors.request.use((config: any) => {
      config.headers["TOKEN"] = getToken()
      return config;
    });
  }

  async post<T>(url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<T> {
    try {
      const resp = await this.axiosInstance.post<{ success: boolean, message: string, data: T }>(url, data, config);
      if (resp.data.success) {
        return resp.data.data;
      } else { //通用异常. 直接显示
        throw new APICommonError(resp.data.message);
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e?.response?.status === 403) { //跳转登录
          throw new APIAuthenticationError();
        } else {
          throw new APIError(-1, e.message, e);
        }
      } else if (e instanceof APIError) {
        throw e;
      } else {
        throw new APIError(-1, e.message || '未知错误', e);
      }
    }
  }

  tts = async (
    text: string,
    options: {
      voice?: string,
      signal?: AbortSignal
    }
  ) => {
    const resp = await axios.post(
      'api/voice/tts',
      {text, voice: options.voice},
      {
        responseType: 'arraybuffer',
        signal: options.signal
      },
    );
    if (resp.status === 204)
      return new ArrayBuffer(0);
    if (resp.status !== 200)
      throw new Error("无法语音合成,错误状态码:" + resp.status);
    return resp.data as ArrayBuffer;
  };

  stt = async () => {
    //建立websocket连接
    const url = new URL(window.location.href);
    url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
    url.pathname = `api/voice/stt`
    //连接到websocket
    const websocket = await WebSocketEx.connect(url.toString());
    //等待成功
    const initSuccessData = await websocket.recv();
    if (!(initSuccessData instanceof CompletedData))
      throw new Error("语音识别发生错误");
    if (!initSuccessData.success)
      throw new Error("云端识别启动失败");
    //返回websocket
    return websocket;
  }

  chat = async <T>(id: string, message: string, tools: ToolEnabled[]) => {
    return await RunState.init<T>(new EventStream(
      'api/session',
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id, message, tools}),
      }
    ))
  }

  upload = async <T>(sessionId: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return await RunState.init<T>(new EventStream(
      `api/document/${sessionId}/uploadFile`,
      {
        method: "POST",
        body: formData
      }
    ));
  }

  browse = async <T>(sessionId: string, url: string) => {
    return await RunState.init<T>(new EventStream(
      'api/session/browse',
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id: sessionId, url}),
      }
    ))
  }

  user = {
    sendCode: (phone: string) => this.post("/user/sendCode", {phone}),
    loginByCode: (phone: string, code: string) => this.post<string>("/user/loginByCode", {phone, code}),
    current: () => this.post<User>("/user/current"),
    updateName: (name: string) => this.post("/user/update", {name}),
  }

  knowledgeBase = {
    publicList: () => this.post<KnowledgeBase[]>("/knowledge-base/publicList"),
    privateList: () => this.post<KnowledgeBase[]>("/knowledge-base/privateList"),
    del: (id: string) => this.post(`/knowledge-base/delete/${id}`),
    upsert: (knowledge: KnowledgeBaseUpsert) => this.post(`/knowledge-base/upsert`, knowledge),
  }

  knowledge = {
    list: (knowledgeBaseId: string, keyword: string | null) => this.post<Knowledge[]>(`/knowledge-base/${knowledgeBaseId}/knowledge/list`, {keyword}),
    del: (knowledgeBaseId: string, id: string) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/delete/${id}`),
    cleanVectoringError: (knowledgeBaseId: string, knowledgeId: string,) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/cleanVectoringError`, {knowledgeId}),
  }
}

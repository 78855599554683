import {EventStream} from "./event_stream";
import {applyPatch} from "rfc6902";

enum StateEvent {
  Init = "init",
  Update = "update",
  Error = "error",
  Success = "success",
}

export class RunState<T> {
  private events: EventStream;
  private currentState: T;

  private constructor(events: EventStream, initState: T) {
    this.events = events;
    this.currentState = initState;
  }

  static async init<T>(events: EventStream): Promise<RunState<T>> {
    const initEvent = await events.recv();
    if (typeof initEvent === "undefined") throw new Error("无法读取初始化状态");
    if (initEvent.type !== StateEvent.Init) throw new Error("错误的初始化状态读取");
    return new RunState<T>(events, initEvent.data);
  }

  /**
   * 获取当前状态
   */
  get current() {
    return this.currentState
  }

  /**
   * 等待状态更新,并返回最新的状态,如果没有更新了,则返回undefined
   */
  async waitUpdate() {
    while (true) {
      const event = await this.events.recv();
      if (typeof event === "undefined") return undefined;

      switch (event.type) {
        case StateEvent.Update:
          const value = structuredClone(this.currentState);
          applyPatch(value, event.data);
          this.currentState = value;
          return this.currentState;

        case StateEvent.Error:
          throw new Error(event.data);

        case StateEvent.Success:
          this.close();
          return undefined;
      }
    }
  }

  close() {
    return this.events.close();
  }
}

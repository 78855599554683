import {useImmer} from "use-immer";
import APIClient, {ToolEnabled} from "../../api";
import {useRef} from "react";
import {RunState} from "../../utils/run_state";

export interface Run {
  completed: boolean
  error?: any
}

export interface UploadState {
  message: string
}

export interface UploadRun extends Run {
  id: string
  file: string
  state?: UploadState
}

export interface Step {
  name: string
  data: any,
  completed: boolean
}

export interface QuestionState {
  steps: Array<Step>,
  messages: Array<string> | null,
}

export interface QuestionRun extends Run {
  question: string
  state: QuestionState,
}

export interface UrlRun extends Run {
  id: string
  url: string
  state: QuestionState
}

export function useSession(id: string) {
  const [runs, updateRuns] = useImmer([] as Array<Run>);

  const state = useRef<RunState<QuestionState>>();

  /**
   * 询问问题
   * @param question 问题本身
   * @param tools 工具是否启用
   * @param voice 是否语音播报
   */
  async function ask(question: string, tools: ToolEnabled[], voice: boolean = false) {
    try {
      //建立一个session
      const runState = await APIClient.chat<QuestionState>(id, question, tools);
      state.current = runState;
      //初始化状态
      updateRuns(runs => {
        runs.push({question, state: runState.current, completed: false} as QuestionRun);
      })
      while (true) {
        const newState = await runState.waitUpdate();
        if (typeof newState === "undefined") {
          return
        }
        updateRuns(runs => {
          const last = runs[runs.length - 1] as QuestionRun;
          last.state = newState;
        })
      }
    } catch (e) {
      updateRuns(runs => {
        const last = runs[runs.length - 1];
        last.error = e;
      });
    } finally {
      updateRuns(runs => {
        const last = runs[runs.length - 1];
        last.completed = true
      });
    }
  }

  async function upload(file: File) {
    const fileId = `${new Date().getTime()}`;
    try {
      //初始化状态
      updateRuns(runs => {
        runs.push({id: fileId, file: file.name, completed: false} as UploadRun);
      })
      //建立一个session
      const runState = await APIClient.upload<UploadState>(id, file);
      updateRuns(runs => {
        const run = runs.find(it => it["id"] === fileId) as UploadRun | null
        if (run) run.state = runState.current;
      });
      while (true) {
        const newState = await runState.waitUpdate();
        if (typeof newState === "undefined") {
          return
        }
        updateRuns(runs => {
          const run = runs.find(it => it["id"] === fileId) as UploadRun | null
          if (run) run.state = newState;
        });
      }
    } catch (e) {
      updateRuns(runs => {
        const run = runs.find(it => it["id"] === fileId) as UploadRun | null
        if (run) run.error = e;
      });
    } finally {
      updateRuns(runs => {
        const run = runs.find(it => it["id"] === fileId) as UploadRun | null
        if (run) run.completed = true
      });
    }
  }

  async function browse(url: string) {
    const messageId = `${new Date().getTime()}`;
    try {
      //建立一个session
      const runState = await APIClient.browse<QuestionState>(id, url);
      //初始化状态
      updateRuns(runs => {
        runs.push({id: messageId, url: url, state: runState.current, completed: false} as UrlRun);
      });
      while (true) {
        const newState = await runState.waitUpdate();
        if (typeof newState === "undefined") {
          return
        }
        updateRuns(runs => {
          const run = runs.find(it => it["id"] === messageId) as UrlRun | null
          if (run) run.state = newState;
        });
      }
    } catch (e) {
      updateRuns(runs => {
        const run = runs.find(it => it["id"] === messageId) as UrlRun | null;
        if (run) run.error = e;
      });
    } finally {
      updateRuns(runs => {
        const run = runs.find(it => it["id"] === messageId) as UrlRun | null;
        if (run) run.completed = true;
      });
    }
  }

  async function cancel() {
    state.current?.close();
  }

  return [runs, ask, upload, browse, cancel] as const;
}

import {UploadRun} from "./session";
import {FaFile} from "react-icons/fa6";
import {Spinner} from "@nextui-org/react";

export default function UploadRunBlock({run}: { run: UploadRun }) {
  return (
    <div className="py-5 animate__animated animate__fadeIn">
      <div className="inline-flex p-4 space-x-2 bg-white border rounded shadow">
        <div className="flex items-center">
          {run.completed ? <FaFile size={36}/> : <Spinner/>}
        </div>
        <div className="flex-col">
          <div className="text-medium font-bold">{run.file}</div>
          <div className="text-sm truncate">
            {run.completed && run.error ? run.error.message : (run.state?.message ?? "正在上传")}
          </div>
        </div>
      </div>
    </div>
  )
}

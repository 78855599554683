import {useRef, useState} from "react";
import {Button} from "@nextui-org/react";
import {CgAttachment} from "react-icons/cg";

export default function UploadButton({onUpload}: { onUpload: (file: File) => Promise<void>; }) {
  /**
   * 同时上传文件的最大数量
   */
  const maxCount = 2

  /**
   * 文件数量
   */
  const [counts, setCounts] = useState(0);

  /**
   * 文件选择
   */
  const inputRef = useRef<HTMLInputElement>(null);

  async function handleFileChanged(e) {
    const file = e.target.files?.[0];
    e.target.value = "";
    if (file == null) return
    let _counts = counts;
    try {
      setCounts(++_counts);
      await onUpload(file);
    } finally {
      setCounts(--_counts);
    }
  }

  return (
    <Button size="sm" className="!outline-none" isIconOnly color="secondary"
            isDisabled={counts >= maxCount}
            onClick={() => inputRef.current?.click()}
    >
      <CgAttachment size={20}/>
      <input ref={inputRef} type="file" accept=".docx,.pdf" hidden
             onChange={handleFileChanged}
      />
    </Button>
  )
}

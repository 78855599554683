import classNames from "classnames";
import {Button, Input} from "@nextui-org/react";
import {FaAngleRight} from "react-icons/fa";
import {useState} from "react";
import {wait} from "../utils/waiter";
import APIClient from "../api";
import {useNavigate} from "react-router-dom";
import {useUser} from "../hooks/useUser";


export default function Name() {
  const navigate = useNavigate();
  const [user, setUser] = useUser();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(user?.name ?? "");
  const [invalidName, setInvalidName] = useState(false);

  async function handleClick() {
    const invalid = name === "";
    setInvalidName(invalid);
    if (invalid) return;

    const waiter = wait(2000);
    try {
      setLoading(true);
      await APIClient.user.updateName(name);
      await waiter;
      setUser(undefined);
      navigate("/", {replace: true});
    } finally {
      await waiter;
      setLoading(false);
    }
  }

  return (
    <div className="h-full w-full flex justify-center items-center bg-gray-100">
      <div className={classNames(
        "h-full",
        "flex flex-col justify-center items-center",
        "w-full px-4",
        "md:w-[720px]",
      )}>
        <div className="w-[340px] h-full pt-[20vh] flex flex-col justify-start gap-10">
          <div className="flex justify-center items-center">
            <img src="/octopus.png" alt="octopus" className="h-20 w-20"/>
          </div>
          <div>
            <div className="pl-2 text-lg font-black">请输入您的称呼</div>
            <div className="pt-2">
              <Input
                autoFocus
                size="lg"
                color="primary"
                variant="bordered"
                radius="sm"
                disabled={loading}
                isInvalid={invalidName}
                errorMessage={invalidName && "称呼不能为空"}
                value={name}
                onValueChange={(name) => {
                  setInvalidName(false);
                  setName(name);
                }}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className="rounded-full" color="primary" isIconOnly size="md" isLoading={loading}
              onClick={handleClick}
            ><FaAngleRight size={20}/></Button>
          </div>
        </div>
      </div>
    </div>
  )
}

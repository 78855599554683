import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {BsRobot} from "react-icons/bs";
import React from "react";
import classNames from "classnames";
import {SlBookOpen} from "react-icons/sl";
import {useUser} from "../../hooks/useUser";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger} from "@nextui-org/react";
import {FaRegUser} from "react-icons/fa";
import {setToken} from "../../hooks/useToken";

export default function ManageHome() {
  const [user] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  let isVisible = true;
  let skipUrl = '/manageHome/commonKnowledgeBaseList';

  if (user?.userType === 'user') {
    isVisible = false;
    skipUrl = '/manageHome/personalKnowledgeBaseList'
  }

  function handleLogout() {
    navigate("/")
    setToken(undefined);
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="h-14 px-10 shadow flex items-center relative">
        <Link to={skipUrl} className="animate__animated animate__fadeIn">
          <div className="flex items-center">
            <BsRobot size={32}/>
            <div className="ml-2 text-large font-bold">小丸子</div>
          </div>
        </Link>
        <div className={
          classNames(
            "h-full flex-1 flex justify-center items-center relative",
            "*:mx-6 *:text-[14px] *:cursor-pointer *:flex *:items-center"
          )
        }>
          {isVisible &&
            <div className="animate__animated animate__fadeInDown *:mx-1"
                 style={{animationDelay: '100ms'}}
                 onClick={() => navigate("/manageHome/commonKnowledgeBaseList")}>
              <div
                className={location.pathname.startsWith("/manageHome/commonKnowledgeBaseList") ? "text-primary" : ""}>
                <SlBookOpen size={18}/>
              </div>
              <div
                className={location.pathname.startsWith("/manageHome/commonKnowledgeBaseList") ? "font-bold text-primary" : ""}>
                公共知识库
              </div>
            </div>}
          <div className="animate__animated animate__fadeInDown *:mx-1"
               style={{animationDelay: '100ms'}}
               onClick={() => navigate("/manageHome/personalKnowledgeBaseList")}>
            <div
              className={location.pathname.startsWith("/manageHome/personalKnowledgeBaseList") ? "text-secondary" : ""}>
              <SlBookOpen size={18}/>
            </div>
            <div
              className={location.pathname.startsWith("/manageHome/personalKnowledgeBaseList") ? "font-bold text-secondary" : ""}>
              个人知识库
            </div>
          </div>
          <div className="absolute top-0 right-0 m-2">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" radius="full" startContent={<FaRegUser size={20}/>}>
                  {user?.name}
                </Button>
              </DropdownTrigger>
              <DropdownMenu variant="flat">
                <DropdownItem onClick={() => navigate('/')} color="primary"
                              className="text-center">返回前台</DropdownItem>
                <DropdownItem onClick={handleLogout} className="text-center" color="danger">注 销</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          {/*<div className={classNames(
            "absolute bottom-0",
            "transition-transform duration-500 ease-in-out",
            location.pathname.startsWith("/manageHome/commonKnowledge")
              ? "translate-x-[-68px] *:bg-primary"
              : (
                location.pathname.startsWith("/manageHome/personalKnowledge")
                  ? "translate-x-[88px] *:bg-secondary"
                  : "translate-x-[128px] *:bg-warning"
              )
          )}>
            <div className="h-1 w-12 animate__animated animate__fadeInUp"/>
          </div>*/}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto px-10 py-8">
        <Outlet/>
      </div>
    </div>
  )
}

import {createBrowserRouter, Navigate, Outlet, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {setToken, useToken} from "./hooks/useToken";
import {useUser} from "./hooks/useUser";
import {wait} from "./utils/waiter";
import APIClient from "./api";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Name from "./pages/Name";
import ManageHome from "./pages/backstage/ManageHome"
import CommonKnowledgeBaseList from "./pages/backstage/knowledge/common/CommonKnowledgeBaseList";
import PersonalKnowledgeBaseList from "./pages/backstage/knowledge/personal/PersonalKnowledgeBaseList";
import KnowledgeList from "./pages/backstage/knowledge/KnowledgeList";

function RouteProtect() {
  const location = useLocation();
  const [token] = useToken();
  const [user, setUser] = useUser();
  useEffect(() => {
    (async () => {
      try {
        const waiter = wait(500);
        const user = await APIClient.user.current();
        await waiter;
        setUser(user);
      } catch (e) {
        setToken(undefined);
        setUser(null);
      }
    })()
  }, [token, setUser])
  //判断是否授权
  const returnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
  //token都没有,直接GG
  if (typeof token === "undefined")
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  //user还没有获取到,等一等
  if (typeof user === "undefined")
    return (
      <div className="h-full flex justify-center items-center">
        <img src="/octopus.png" alt="octopus" className="h-20 w-20"/>
      </div>
    )
  //user已经获取到,但是不存在,直接前往登陆页面
  if (user === null)
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  //user.name未设置且路径不是/name, 直接前往用户名称设置页面
  if ((user.name === null || user.name === "") && location.pathname !== "/name")
    return <Navigate to={`/name`} replace/>;
  return <Outlet/>
}

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/",
    element: <RouteProtect/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "/name",
        element: <Name/>
      },
      {
        path: "/manageHome",
        element: <ManageHome/>,
        children: [
          {
            index: true,
            element: <Navigate to="commonKnowledgeBaseList"/>
          },
          {
            path: "commonKnowledgeBaseList",
            element: <CommonKnowledgeBaseList/>
          },
          {
            path: "personalKnowledgeBaseList",
            element: <PersonalKnowledgeBaseList/>
          },
          {
            path: ":knowledgeBaseId",
            children: [
              {
                path: "knowledge",
                children: [
                  {
                    index: true,
                    element: <KnowledgeList/>
                  }
                ]
              }
            ]
          },
        ]
      }
    ]
  },
]);

import {Button, Divider, Spinner, Switch, Textarea} from "@nextui-org/react";
import {FiSend} from "react-icons/fi";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {useList} from "react-use";
import ScrollView from "../../components/ScrollView";
import {QuestionRun, UploadRun, UrlRun, useSession} from "./session";
import RunBlock from "./RunBlock";
import UploadRunBlock from "./UploadRunBlock";
import classNames from "classnames";
import {FaRegUser, FaStop} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import APIClient from "../../api";
import {wait} from "../../utils/waiter";
import {TbDatabaseSearch, TbWorldSearch} from "react-icons/tb";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import Emoji from "../../components/Emoji";
import UploadButton from "./UploadButton";
import UrlRunBlock from "./UrlRunBlock";

enum ToolType {
  Search = 'search',
  KnowledgeBase = 'knowledge_base',
}

interface ToolItem {
  id: string;
  type: ToolType;
  name: string;
  icon?: string;
  enabled: boolean;
  disabled: boolean;
}

function ToolBlock({tool, onUpdate}: { tool: ToolItem, onUpdate: (tool: ToolItem) => void }) {
  return (
    <div className={classNames(
      "h-10 p-2",
      "border rounded shadow",
      "flex flex-row items-center gap-2"
    )}>
      {
        (() => {
          const icon = tool.icon ? <Emoji icon={tool.icon} size={26}/> : <TbDatabaseSearch size={20}/>
          switch (tool.type) {
            case ToolType.KnowledgeBase:
              return icon;
            case ToolType.Search:
              return <TbWorldSearch size={20}/>
          }
        })()
      }
      <div>{tool.name}</div>
      <Divider orientation="vertical"/>
      <Switch size="sm" aria-label="是否启用" isSelected={tool.enabled} isDisabled={tool.disabled}
              onValueChange={(v) => {
                tool.enabled = v;
                onUpdate(tool)
              }}/>
    </div>
  )
}

const TextAreaWrapper = styled.div`
  flex: 1;

  *:focus-visible {
  }

  > div {

    > div {
      box-shadow: none !important;

      > div {
        > textarea {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        > textarea::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`

export default function Home() {
  const [sessionID] = useState(new Date().getTime().toString())
  const [text, setText] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [runs, ask, upload, browse, cancel] = useSession(sessionID);
  const navigate = useNavigate();
  const [user] = useUser();

  const [isToolsLoading, setIsToolsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  const [tools, setTools] = useList<ToolItem>([{
    id: `_search_`,
    name: '在线搜索',
    type: ToolType.Search,
    enabled: false,
    disabled: false,
  }]);
  useEffect(() => {
    (async () => {
      const waiter = wait();
      setIsToolsLoading(true)
      try {
        const privateKnowledgeBaseTools = (await APIClient.knowledgeBase.privateList()).map<ToolItem>(it => ({
          id: it.id,
          name: it.name,
          icon: it.icon,
          type: ToolType.KnowledgeBase,
          enabled: true,
          disabled: false,
        }));
        const publicKnowledgeBaseTools = (await APIClient.knowledgeBase.publicList()).map<ToolItem>(it => ({
          id: it.id,
          name: it.name,
          type: ToolType.KnowledgeBase,
          icon: it.icon,
          enabled: true,
          disabled: false,
        }));
        setTools.set([
            ...privateKnowledgeBaseTools,
            ...publicKnowledgeBaseTools,
            {id: `_search_`, name: '在线搜索', type: ToolType.Search, enabled: false, disabled: false}
          ]
        );
        await waiter;
      } finally {
        await waiter;
        setIsToolsLoading(false);
      }
    })()
  }, [setTools]);

  /**
   * 禁用工具(知识库和在线搜索)
   */
  function disabledTools() {
    setTools.set(tools.map(it => ({
      ...it,
      disabled: true,
      enabled: false,
    })))
  }

  /**
   * 发送消息. 判断url消息还是提问消息
   */
  async function handleSend() {
    const content = text.trim();
    if (content === "") return
    //url匹配
    const urls = content.match(/https?:\/\/\S+/g)
    //包含url, 禁用工具
    if ((urls?.length ?? 0) > 0) disabledTools();
    if (urls?.[0] === content) { //纯url
      await handleUrl(content)
    } else {
      await handleAsk(content);
    }
  }

  /**
   * 询问问题
   *
   * @param question 问题
   */
  async function handleAsk(question?: string) {
    question = question ?? text;
    if (question.trim() === "") return;
    if (isRunning) return;
    try {
      setText('');
      setIsRunning(true);
      await ask(question, tools.map(it => ({id: it.id, enabled: it.enabled})));
      await wait(500);
    } finally {
      setIsRunning(false);
    }
  }

  /**
   * 上传文件
   *
   * @param file 文件
   */
  async function handleUpload(file: File) {
    if (isRunning) return;
    disabledTools();
    await upload(file);
  }

  /**
   * 发送url消息
   *
   * @param url url链接
   */
  async function handleUrl(url: string) {
    if (isRunning) return;
    try {
      setText('');
      await browse(url);
      await wait(500);
    } finally {
    }
  }

  function handleSkip() {
    if (user?.userType === 'user') {
      navigate("/manageHome/personalKnowledgeBaseList")
    } else if (user?.userType === 'admin') {
      navigate("/manageHome/commonKnowledgeBaseList")
    }
  }

  return (
    <div className="h-full w-full flex justify-center bg-gray-100">
      <div style={{
        position: "absolute",
        top: 10,
        right: 10
      }}>
        <Button variant="light" radius="full" startContent={<FaRegUser size={20}/>}
                onClick={() => handleSkip()}>
          进入后台
        </Button>
      </div>
      <div className={classNames(
        "h-full",
        "flex flex-col justify-center items-center",
        "w-full px-4",
        "md:w-[720px]",
      )}>
        {runs.length === 0 && (
          <div className="flex justify-center items-center space-x-3 py-5">
            <img src="/octopus.png" alt="octopus" className="h-20 w-20"/>
            <h1 className="text-3xl font-bold">小丸子</h1>
          </div>
        )}
        {runs.length > 0 && (
          <div className="flex-1 w-full overflow-hidden">
            <ScrollView always={isRunning}>
              {runs
                .map((it, index) => {
                  if (it["question"]) {
                    const run = it as QuestionRun;
                    return <RunBlock key={index} run={run} ask={handleAsk}
                                     isLatest={index === runs.length - 1}/>
                  }
                  if (it["file"]) {
                    const run = it as UploadRun;
                    return <UploadRunBlock key={index} run={run}/>
                  }
                  if (it["url"]) {
                    const run = it as UrlRun;
                    return <UrlRunBlock key={index} run={run}/>
                  }
                  return <></>
                })
                .reduce<JSX.Element[]>((acc, val) => acc.concat(<Divider key={val.key + "div"}/>, val), []).slice(1)
              }
            </ScrollView>
          </div>
        )}
        {isToolsLoading
          ? <Spinner/>
          : <div className="w-full flex flex-col items-center space-x-3 px-2 py-5 gap-2">
            <div className="w-full flex items-center space-x-3">
              <UploadButton onUpload={handleUpload}/>
              <TextAreaWrapper>
                <Textarea color="secondary" variant="faded" autoFocus
                          minRows={1} maxRows={3} maxLength={250}
                          placeholder={runs.length > 0 ? "继续追问" : "OK, 你有什么问题?"}
                          onKeyDown={async event => {
                            if (!event.shiftKey && event.key === "Enter") {
                              event.preventDefault();
                              await handleSend();
                            }
                          }}
                          value={text} onValueChange={setText}
                />
              </TextAreaWrapper>
              {
                isRunning
                  ? (<Button size="sm" className="!outline-none"
                             isIconOnly color="danger"
                             onClick={() => cancel()}
                  >
                    <FaStop/>
                  </Button>)
                  : (<Button size="sm" className="!outline-none"
                             isIconOnly color="primary"
                             onClick={() => handleSend()}
                    >
                      <FiSend size={20}/>
                    </Button>
                  )
              }
            </div>
            <div onClick={() => setIsExpanded(v => !v)}>
              <div>{isExpanded ? <IoIosArrowUp/> : <IoIosArrowDown/>}</div>
            </div>
            {isExpanded && (
              <div className="w-full p-2">
                <div className="flex flex-wrap gap-2">
                  {tools.map((it, index) => (
                    <ToolBlock key={it.id} tool={it} onUpdate={(tool) => {
                      setTools.updateAt(index, tool)
                    }}/>))}
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  )
}

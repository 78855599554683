import {UrlRun} from "./session";
import {Spinner} from "@nextui-org/react";
import {FaInternetExplorer} from "react-icons/fa";

export default function UrlRunBlock({run}: { run: UrlRun }) {
  const messages = run.state.messages ?? [];
  let message = "正在搜索网页";
  if (messages.length > 0) {
    message = messages[messages.length - 1];
  }
  return (
    <div className="py-5 animate__animated animate__fadeIn">
      <div className="inline-flex p-4 space-x-2 bg-white border rounded shadow">
        <div className="flex items-center">
          {run.completed ? <FaInternetExplorer size={36}/> : <Spinner/>}
        </div>
        <div className="flex-col">
          <div className="text-medium font-bold">{run.url}</div>
          <div className="text-sm truncate">
            {run.completed && run.error ? run.error.message : message}
          </div>
        </div>
      </div>
    </div>
  )
}
